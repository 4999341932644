import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import DescriptionIcon from "@material-ui/icons/Description";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import dealsService from "../services/dealsService";
import CalculatorIcon from "../components/layout/CalculatorIcon";
import DealCalc from "../components/deal/calc/dealCalc";
import DealDocs from "../components/deal/docs/dealDocs";
import dealService from "../services/dealsService";
import ManageComponent from "../components/shared/manageComponent";
import DealReminders from "../components/deal/reminders/dealReminders";
import TabContainer from "../components/layout/TabContainer";
import config from "../config";

const topNavOpts = {
  CALC: "CALC",
  MANAGE: "MANAGE",
  DOCS: "DOCS",
  REMINDERS: "REMINDERS"
};

class DealsContainer extends Component {
  state = {
    dealId: null,
    prettyId: null,
    dealDate: "",
    isDealDateChanged: false,
    containerTabs: {
      currentNavFocused: topNavOpts.CALC,
      tabs: [
        {
          key: topNavOpts.CALC,
          text: "חישוב מס שבח",
          icon: <CalculatorIcon color="secondary" />,
          path: "/deal/calc/:dealId",
          component: (props) => <DealCalc {...props} addresses={this.state.addresses} />,
          stateFamily: "calc",
          functions: {}
        },
        {
          key: topNavOpts.MANAGE,
          text: "ניהול עסקה",
          icon: <AccountTreeIcon color="secondary" />,
          path: "/deal/manage/:dealId",
          component: (props) => <ManageComponent {...props} isFullManageComponent={true} dealDate={this.state.dealDate} />,
          stateFamily: "manage",
          functions: {}
        },
        {
          key: topNavOpts.DOCS,
          text: "טפסים",
          icon: <DescriptionIcon color="secondary" />,
          path: "/deal/docs/:dealId",
          component: (props) => <DealDocs {...props} />,
          stateFamily: "docs",
          functions: {}
        },
        {
          key: topNavOpts.REMINDERS,
          text: "התראות ותזכורות",
          icon: <NotificationsIcon color="secondary" />,
          path: "/deal/reminders/:dealId",
          component: (props) => (
            <DealReminders
              {...props}
              dealPrettyId={this.state.prettyId}
              dealDescription={this.state.manage ? this.state.manage.dealDescription : ""}
            />
          ),
          stateFamily: "reminders",
          functions: {}
        }
      ],
      isLoaded: true
    },
    calc: {
      currentStep: 1,
      isSnackbarOpen: true,
      isPreCalcMsgAllowed: false,
      preCalc: {
        sellerType: "single",
        propertyType: "dmm",
        soldPropertyDate: moment(new Date()).format("YYYY-MM-DD"),
        howPropertyIsSold: "allPropertySold",
        iazamCombinationPercent: null,
        percentHoldingShares: null,
        totalPropertyCost: null,
        valueActualSoldOfProperty: null,
        totalPropertyCostWithoutZaban: null,
        zabanMeters: null,
        hasZaban: false,
        isOnlyProperty: false,
        stepsBought: 1,
        cosnstructionsDoneBeforeApril1997: false,
        preCalcPartsData: [
          {
            percentBought: 100,
            wayOfGettingProperty: "purchase",
            isMutavAllowed: true,
            isInheritancePtorAllowed: false,
            isSinglePropertyPtorAllowed: false,
            hasTzinunOnPtor: false,
            hasMutavTzinun: false
          }
        ],
        hasResults: false,
        recommendations: null,
        miraArielMsg: false,
        totalDealSoldPercent: null
      },
      mainCalc: {}
    },
    manage: {
      dealDescription: "",
      hasEaaratAzhara: false,
      hasMasShevachReportsSubmitted: false,
      masShavach: false,
      masRechisha: false,
      haitelHashbacha: false
    },
    docs: {
      currentStep: 1,
      isFillDocumnetsAllowed: false,
      docTypes: {
        taboAndRMIType: "tabo",
        shtarMecher: false,
        eaaratAzharaOneSide: false,
        eaaratAzharaAllSides: false,
        noTaboLeasingRight: false,
        taboPassingLeasingRight: false,
        taboFixLeasingRight: false,
        taboCancelLeasingRight: false,
        taboRegisterLeasingRight: false,
        ipuiKoachBuyer: false,
        ipuiKoachSeller: false,
        ipuiKoachEaaratAzhara: false,
        fillDetailFileOnline: false
      }
    },
    reminders: {},
    addresses: []
  };

  constructor(props) {
    super(props);
    this.state.containerTabs.tabs[0].functions = {
      handlePreCalcOnChange: this.handlePreCalcOnChange,
      handlePreCalcPartOnChange: this.handlePreCalcPartOnChange,
      handleCombinationOnChange: this.handleCombinationOnChange,
      preCalcStart: this.preCalcStart,
      handleStepChange: this.handleStepChange,
      getPrisaMaxYears: this.getPrisaMaxYears,
      getPrisaMinYears: this.getPrisaMinYears,
      getPurchaseDate: this.getPurchaseDate,
      handleMainCalcPrisaOnChange: this.handleMainCalcPrisaOnChange,
      handleMainCalcOnChange: this.handleMainCalcOnChange,
      addMainCalcDeduction: this.addMainCalcDeduction,
      addMainCalcDeductionsFromExcel: this.addMainCalcDeductionsFromExcel,
      removeMainCalcDeduction: this.removeMainCalcDeduction,
      handleMainCalcDeductionOnChange: this.handleMainCalcDeductionOnChange,
      mainCalcStart: this.mainCalcStart,
      updateDealData: this.updateDealData,
      handleSnapbarClose: this.handleSnapbarClose,
      hasTaxToPay: this.hasTaxToPay,
      exportDealData: this.exportDealData
    };
    this.state.containerTabs.tabs[1].functions = { handleManageOnChange: this.handleManageOnChange, updateDealData: this.updateDealData };
    this.state.containerTabs.tabs[2].functions = {
      changeDocsStep: this.changeDocsStep,
      handleDocTypesOnChange: this.handleDocTypesOnChange
    };
    this.state.containerTabs.tabs[3].functions = {};
  }

  componentDidMount() {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
    const pathname = window.location.pathname;
    const startIndex = pathname.indexOf("/", 1) + 1;
    const lastIndex = pathname.lastIndexOf("/");
    const getOpt = pathname.substring(startIndex, lastIndex);
    const dealId = this.props.match.params.dealId;
    dealsService.getDealData(dealId).then((res) => {
      if (res.success) {
        if (!res.dealData || res.dealData.softwareServiceType !== "btax") {
          this.props.history.replace("/my-deals");
        } else {
          const manage = res.dealData.manage ? this.parseStateManageFromServer(res.dealData.manage) : { ...this.state.manage };
          const calc = res.dealData.calc ? this.parseStateCalcFromServer(res.dealData.calc) : { ...this.state.calc };
          const dealDate = calc.preCalc.soldPropertyDate || moment(res.dealData.dateCreated).format("YYYY-MM-DD");
          const prettyId = res.dealData.prettyId;
          if (getOpt && topNavOpts[getOpt.toUpperCase()]) {
            const containerTabs = { ...this.state.containerTabs, currentNavFocused: topNavOpts[getOpt.toUpperCase()] };
            this.setState({ containerTabs, calc, manage, dealId, prettyId, dealDate });
          } else {
            const containerTabs = { ...this.state.containerTabs, currentNavFocused: topNavOpts.CALC };
            this.setState({ containerTabs, calc, manage, dealId, prettyId, dealDate });
          }
        }
      } else {
        this.props.history.replace("/my-deals");
      }
    });

    dealsService.getAddresses().then((res) => {
      if (res.success) {
        this.setState({ addresses: res.addrsses });
      }
    });
  }

  componentDidUpdate() {
    const pathname = window.location.pathname;
    const startIndex = pathname.indexOf("/", 1) + 1;
    const lastIndex = pathname.lastIndexOf("/");
    const getOpt = pathname.substring(startIndex, lastIndex);
    if (this.state.containerTabs.currentNavFocused !== topNavOpts[getOpt.toUpperCase()]) {
      const containerTabs = { ...this.state.containerTabs, currentNavFocused: topNavOpts[getOpt.toUpperCase()] };
      this.setState({ containerTabs });
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.dealId ? (
          <TabContainer
            title={this.state.prettyId ? "מספר עסקה " + this.state.prettyId : ""}
            subTitle={this.state.manage.dealDescription}
            calc={this.state.calc}
            manage={this.state.manage}
            docs={this.state.docs}
            reminders={this.state.reminders}
            optionalTabs={this.state.containerTabs.tabs}
            currentNavFocused={this.state.containerTabs.currentNavFocused}
            onTopNavChanged={this.onTopNavChanged}
            isLoaded={this.state.containerTabs.isLoaded}
          />
        ) : null}
      </Fragment>
    );
  }

  onTopNavChanged = (newState) => {
    const containerTabs = { ...this.state.containerTabs, currentNavFocused: newState, isLoaded: false };
    this.setState({ containerTabs }, () => {
      this.props.history.push("/deal/" + newState.toLowerCase() + "/" + this.state.dealId);
      const containerTabs = { ...this.state.containerTabs, isLoaded: true };
      this.setState({ containerTabs });
    });
  };

  updateDealData = () => {
    let errorMsg;

    if (this.state.calc.currentStep === 1) errorMsg = this.preCalcValidJustSaving();
    else if (this.state.calc.currentStep === 2) errorMsg = this.mainCalcValidJustSaving();

    if (errorMsg) {
      return Swal.fire("", errorMsg, "error");
    } else {
      dealsService.updateDealData(this.state.dealId, this.state.calc, this.state.manage, this.state.isDealDateChanged).then((res) => {
        if (res.success) {
          Swal.fire("העסקה נשמרה בהצלחה", "", "success");
          if (this.state.isDealDateChanged) {
            this.setState({ isDealDateChanged: false });
          }
        }
      });
    }
  };

  exportDealData = () => {
    const exportedData = {
      calc: { preCalc: this.state.calc.preCalc, mainCalc: this.state.calc.mainCalc },
      manage: this.state.manage,
      softwareServiceType: "btax"
    };
    console.log(exportedData);
    navigator.clipboard.writeText(JSON.stringify(exportedData, null, 2));
  };

  parseStateManageFromServer = (manage) => {
    return {
      dealDescription: manage.dealDescription ? manage.dealDescription : "",
      hasEaaratAzhara: manage.hasEaaratAzhara ? manage.hasEaaratAzhara : false,
      hasMasShevachReportsSubmitted: manage.hasMasShevachReportsSubmitted ? manage.hasMasShevachReportsSubmitted : false,
      masShavach: manage.masShavach ? manage.masShavach : false,
      masRechisha: manage.masRechisha ? manage.masRechisha : false,
      haitelHashbacha: manage.haitelHashbacha ? manage.haitelHashbacha : false
    };
  };

  parseStateCalcFromServer = (calc) => {
    // preCalc:
    // preCalc.preCalcPartsData
    const preCalcPartsData = [];
    if (calc.preCalc && calc.preCalc.preCalcPartsData && Array.isArray(calc.preCalc.preCalcPartsData)) {
      for (const part of calc.preCalc.preCalcPartsData) {
        const item = {
          percentBought: Number.isFinite(part.percentBought) ? part.percentBought : 100,
          wayOfGettingProperty: part.wayOfGettingProperty ? part.wayOfGettingProperty : "purchase",
          isMutavAllowed: typeof part.isMutavAllowed === "boolean" ? part.isMutavAllowed : true,
          isInheritancePtorAllowed: part.isInheritancePtorAllowed ? part.isInheritancePtorAllowed : false,
          isSinglePropertyPtorAllowed: part.isSinglePropertyPtorAllowed ? part.isSinglePropertyPtorAllowed : false,
          hasTzinunOnPtor: part.hasTzinunOnPtor ? part.hasTzinunOnPtor : false,
          hasMutavTzinun: part.hasMutavTzinun ? part.hasMutavTzinun : false
        };
        if (part.baseAmount !== undefined) item.baseAmount = part.baseAmount;
        if (part.baseMethodType !== undefined) item.baseMethodType = part.baseMethodType;
        if (part.deltaAmount !== undefined) item.deltaAmount = part.deltaAmount;
        if (part.deltaMethodType !== undefined) item.deltaMethodType = part.deltaMethodType;
        if (part.zabanAllowedAnyPtor !== undefined) item.zabanAllowedAnyPtor = part.zabanAllowedAnyPtor;
        if (part.zabanOldLinear !== undefined) item.zabanOldLinear = part.zabanOldLinear;
        if (part.needsMutav !== undefined) item.needsMutav = part.needsMutav;
        if (part.needsRegTax !== undefined) item.needsRegTax = part.needsRegTax;
        preCalcPartsData.push(item);
      }
    }

    // Build preCalc
    const preCalc = {
      sellerType: calc.preCalc.sellerType ? calc.preCalc.sellerType : "single",
      propertyType: calc.preCalc.propertyType ? calc.preCalc.propertyType : "dmm",
      soldPropertyDate: calc.preCalc.soldPropertyDate
        ? moment(calc.preCalc.soldPropertyDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      howPropertyIsSold: calc.preCalc.howPropertyIsSold ? calc.preCalc.howPropertyIsSold : "allPropertySold",
      iazamCombinationPercent: Number.isFinite(calc.preCalc.iazamCombinationPercent) ? calc.preCalc.iazamCombinationPercent : 1,
      percentHoldingShares: Number.isFinite(calc.preCalc.percentHoldingShares) ? calc.preCalc.percentHoldingShares : null,
      totalPropertyCost: Number.isFinite(calc.preCalc.totalPropertyCost) ? calc.preCalc.totalPropertyCost : null,
      valueActualSoldOfProperty: Number.isFinite(calc.preCalc.valueActualSoldOfProperty) ? calc.preCalc.valueActualSoldOfProperty : null,
      totalPropertyCostWithoutZaban: Number.isFinite(calc.preCalc.totalPropertyCostWithoutZaban)
        ? calc.preCalc.totalPropertyCostWithoutZaban
        : null,
      zabanMeters: calc.preCalc.zabanMeters ? calc.preCalc.zabanMeters : null,
      hasZaban: calc.preCalc.hasZaban ? calc.preCalc.hasZaban : false,
      isOnlyProperty: calc.preCalc.isOnlyProperty ? calc.preCalc.isOnlyProperty : false,
      stepsBought: Number.isFinite(calc.preCalc.stepsBought) ? calc.preCalc.stepsBought : 1,
      cosnstructionsDoneBeforeApril1997: calc.preCalc.cosnstructionsDoneBeforeApril1997
        ? calc.preCalc.cosnstructionsDoneBeforeApril1997
        : false,
      preCalcPartsData,
      hasResults: calc.preCalc.hasResults ? calc.preCalc.hasResults : false,
      recommendations: calc.preCalc.recommendations ? calc.preCalc.recommendations : null,
      miraArielMsg: calc.preCalc.miraArielMsg ? calc.preCalc.miraArielMsg : false,
      totalDealSoldPercent: Number.isFinite(calc.preCalc.totalDealSoldPercent) ? calc.preCalc.totalDealSoldPercent : null
    };

    // mainCalc:
    const mainCalc = {};
    // mainCalc.prisa
    if (calc.mainCalc) {
      const personsData = [];
      if (calc.mainCalc.prisa && calc.mainCalc.prisa.personsData && Array.isArray(calc.mainCalc.prisa.personsData)) {
        for (const person of calc.mainCalc.prisa.personsData) {
          let dataPerYear = [];
          if (person.dataPerYear && Array.isArray(person.dataPerYear)) {
            for (const year of person.dataPerYear) {
              dataPerYear.push({
                income: Number.isFinite(year.income) ? year.income : null,
                zikui: Number.isFinite(year.zikui) ? year.zikui : 0
              });
            }
          } else {
            dataPerYear = [
              { income: null, zikui: 0 },
              { income: null, zikui: 0 },
              { income: null, zikui: 0 },
              { income: null, zikui: 0 }
            ];
          }
          personsData.push({
            personName: person.personName ? person.personName : "",
            isMaximal: person.isMaximal ? person.isMaximal : false,
            personOwningPercent: Number.isFinite(person.personOwningPercent) ? person.personOwningPercent : 1,
            birthOfClient: person.birthOfClient ? moment(person.birthOfClient).format("YYYY-MM-DD") : "",
            dataPerYear
          });
        }
      } else {
        personsData.push({
          personName: "",
          isMaximal: true,
          personOwningPercent: 1,
          birthOfClient: "",
          dataPerYear: [
            { income: null, zikui: 0 },
            { income: null, zikui: 0 },
            { income: null, zikui: 0 },
            { income: null, zikui: 0 }
          ]
        });
      }

      const prisa = {
        shouldTryPrisa: calc.mainCalc.prisa.shouldTryPrisa ? calc.mainCalc.prisa.shouldTryPrisa : false,
        personsData
      };

      // mainCalc.deductions
      const deductions = [];
      if (calc.mainCalc.deductions && Array.isArray(calc.mainCalc.deductions)) {
        for (let i = 0; i < calc.mainCalc.deductions.length; i++) {
          const part = calc.mainCalc.deductions[i];
          const depreciation = {};
          if (typeof part.depreciation === "object" && part.depreciation !== null && Object.keys(part.depreciation).length !== 0) {
            depreciation.depType = part.depreciation.depType
              ? part.depreciation.depType
              : calc.preCalc.sellerType === "single" && calc.preCalc.propertyType === "dmm"
              ? "ptor"
              : "regTax";
            depreciation.depCurrency = part.depreciation.depCurrency ? part.depreciation.depCurrency : "newShekel";
            depreciation.depreciationBuiltPercent = Number.isFinite(part.depreciation.depreciationBuiltPercent)
              ? part.depreciation.depreciationBuiltPercent
              : 0.02;
            depreciation.depreciationPercent = Number.isFinite(part.depreciation.depreciationPercent)
              ? part.depreciation.depreciationPercent
              : 1;
            depreciation.depreciationResult = Number.isFinite(part.depreciation.depreciationResult)
              ? part.depreciation.depreciationResult
              : null;
            if (part.depreciation.dateRanges && Array.isArray(part.depreciation.dateRanges)) {
              const dateRanges = [];
              for (const range of part.depreciation.dateRanges) {
                dateRanges.push({
                  startDate: range.startDate ? moment(range.startDate).format("YYYY-MM-DD") : "",
                  endDate: range.endDate ? moment(range.endDate).format("YYYY-MM-DD") : ""
                });
              }
              depreciation.dateRanges = dateRanges;
            } else {
              depreciation.dateRanges = [{ startDate: "", endDate: "" }];
            }
          }
          const deduction = {
            deduType: part.deduType ? part.deduType : "purchase",
            applyFullDeductionAmount: part.applyFullDeductionAmount || false,
            isInsertDeductionAsNegativeVaue: part.isInsertDeductionAsNegativeVaue || false,
            amount: Number.isFinite(part.amount) ? part.amount : null,
            deduCurrency: part.deduCurrency ? part.deduCurrency : "newShekel",
            hasRabinovich: part.hasRabinovich ? part.hasRabinovich : false,
            attribution: part.attribution ? part.attribution : calc.preCalc.hasZaban ? "both" : "apartment",
            belongToParts: Array.isArray(part.belongToParts) ? part.belongToParts : [],
            depreciation
          };
          if (part.deduType === "inheritance") {
            deduction.testatorBoughtPropertyDate = part.testatorBoughtPropertyDate
              ? moment(part.testatorBoughtPropertyDate).format("YYYY-MM-DD")
              : "";
            deduction.testatorPassingDate = part.testatorPassingDate ? moment(part.testatorPassingDate).format("YYYY-MM-DD") : "";
          } else {
            deduction.deduDate = part.deduDate ? moment(part.deduDate).format("YYYY-MM-DD") : "";
          }
          deductions.push(deduction);
        }
      }
      mainCalc.deductions = deductions;
      mainCalc.prisa = prisa;
      mainCalc.kizuzIski = Number.isFinite(calc.mainCalc.kizuzIski) ? calc.mainCalc.kizuzIski : null;
      mainCalc.kizuzHoni = Number.isFinite(calc.mainCalc.kizuzHoni) ? calc.mainCalc.kizuzHoni : null;
      mainCalc.isGilum = calc.mainCalc.isGilum ? calc.mainCalc.isGilum : false;
      mainCalc.afkaa = calc.mainCalc.afkaa ? calc.mainCalc.afkaa : false;
      mainCalc.hasResults = calc.mainCalc.hasResults ? calc.mainCalc.hasResults : false;
      // mainCalc.calculatedData (Optional)
      if (calc.mainCalc.calculatedData) {
        mainCalc.calculatedData = calc.mainCalc.calculatedData;
      }

      if (calc.mainCalc.overrideTkufaPercent) {
        mainCalc.overrideTkufaPercent = calc.mainCalc.overrideTkufaPercent;
      }
    } else {
      this.initializeMainCalc(mainCalc, preCalc);
    }

    return {
      currentStep: 1,
      isSnackbarOpen: true,
      preCalc,
      mainCalc
    };
  };

  // Calc Tab functions:
  preCalcValidations() {
    const today = moment(new Date()).format("YYYY-MM-DD");
    let sumPercentBought = this.state.calc.preCalc.preCalcPartsData.reduce((a, b) => a + b.percentBought, 0);
    // this line is to make 100.00000000001 => 100
    sumPercentBought = parseFloat(sumPercentBought.toFixed(6));
    if (!this.state.calc.preCalc.soldPropertyDate) {
      return "תאריך המכירה הינו שדה חובה";
    } else if (this.state.calc.preCalc.soldPropertyDate > today) {
      return "תאריך המכירה לא יכול להיות תאריך עתידי";
    } else if (this.state.calc.preCalc.soldPropertyDate < config.earliestAllowedProperySoldDate) {
      return "לא ניתן להזין מועד מכירה מלפני " + config.earliestAllowedProperySoldDate;
    } else if (!this.state.calc.preCalc.totalPropertyCost) {
      return `שווי ${this.state.calc.preCalc.howPropertyIsSold === "combination" ? "הקומבינציה" : "הנכס הכולל"} הינו שדה חובה`;
    } else if (this.state.calc.preCalc.valueActualSoldOfProperty > this.state.calc.preCalc.totalPropertyCost) {
      return "שווי החלק הנמכר לא יכול לעלות על שווי הנכס הכולל";
    } else if (this.state.calc.preCalc.howPropertyIsSold === "partOfPropertySold" && !this.state.calc.preCalc.valueActualSoldOfProperty) {
      return "שווי החלק הנמכר הינו שדה חובה";
    } else if (this.state.calc.preCalc.totalPropertyCostWithoutZaban > this.state.calc.preCalc.totalPropertyCost) {
      return "שווי הדירה לא יכול לעלות על שווי הנכס הכולל";
    } else if (this.state.calc.preCalc.hasZaban && !this.state.calc.preCalc.totalPropertyCostWithoutZaban) {
      return "שווי הדירה הינו שדה חובה";
    } else if (sumPercentBought > 100 || sumPercentBought < 99.99) {
      return "האחוז הנרכש של כל החלקים צריך להיות שווה ל-100%";
    } else if (this.state.calc.preCalc.hasZaban) {
      if (Number.isFinite(this.state.calc.preCalc.zabanMeters)) {
        if (this.state.calc.preCalc.zabanMeters < config.minZabanAllowed || this.state.calc.preCalc.zabanMeters > config.maxZabanAllowed) {
          return "הוזנה כמות לא חוקית של מטרים לזכויות הבניה";
        }
      }
    }
  }

  preCalcStart = () => {
    const err = this.preCalcValidations();
    if (err) {
      return Swal.fire("", err, "error");
    } else {
      let mainCalc = {};
      const preCalcPointer = this.state.calc.preCalc;
      if (Object.keys(this.state.calc.mainCalc).length) {
        mainCalc = this.initializeMainCalc(this.state.calc.mainCalc, preCalcPointer, true);
      } else {
        this.initializeMainCalc(mainCalc, preCalcPointer);
      }

      dealService.preCalcStart(preCalcPointer, this.state.dealId, this.state.isDealDateChanged, mainCalc).then((res) => {
        if (res["success"]) {
          const preCalc = {
            ...preCalcPointer,
            preCalcPartsData: res.data.preCalcPartsData,
            recommendations: res.data.recommendations,
            miraArielMsg: res.data.miraArielMsg,
            totalDealSoldPercent: res.data.totalDealSoldPercent,
            hasResults: true
          };

          const calc = { ...this.state.calc, preCalc, mainCalc };
          this.setState({ calc, isDealDateChanged: false });
        }
      });
    }
  };

  preCalcValidJustSaving = () => {
    if (this.state.calc.preCalc.soldPropertyDate < config.earliestAllowedProperySoldDate) {
      return "לא ניתן להזין מועד מכירה מלפני " + config.earliestAllowedProperySoldDate;
    } else if (this.state.calc.preCalc.hasZaban) {
      if (Number.isFinite(this.state.calc.preCalc.zabanMeters)) {
        if (this.state.calc.preCalc.zabanMeters < config.minZabanAllowed || this.state.calc.preCalc.zabanMeters > config.maxZabanAllowed) {
          return "הוזנה כמות לא חוקית של מטרים לזכויות הבניה";
        }
      }
    }
    if (this.state.calc.preCalc.soldPropertyDate > moment().format("YYYY-MM-DD")) {
      return "לא ניתן להזין מועד מכירה עתידי";
    }
  };

  mainCalcValidJustSaving = () => {
    for (let i = 1; i <= this.state.calc.mainCalc.deductions.length; i++) {
      const deduction = this.state.calc.mainCalc.deductions[i - 1];
      const today = moment(new Date()).format("YYYY-MM-DD");
      const minDeduDateAllowed = moment(new Date("1923-01-01")).format("YYYY-MM-DD");

      if (deduction.deduDate > today) {
        return `ניכוי מספר ${i} - תאריך הניכוי לא יכול להיות תאריך עתידי`;
      } else if (deduction.deduDate < minDeduDateAllowed) {
        return `ניכוי מספר ${i} - תאריך הניכוי לא יכול להיות תאריך לפני ${minDeduDateAllowed}`;
      } else if (deduction.deduType === "inheritance") {
        if (deduction.testatorPassingDate) {
          if (deduction.testatorPassingDate > today) {
            return `ניכוי מספר ${i} - תאריך פטירת המוריש לא יכול להיות תאריך עתידי`;
          } else if (deduction.testatorPassingDate < minDeduDateAllowed) {
            return `ניכוי מספר ${i} - תאריך פטירת המוריש לא יכול להיות לפני ${minDeduDateAllowed}`;
          } else if (deduction.testatorPassingDate >= "1981-04-01") {
            if (deduction.testatorBoughtPropertyDate) {
              if (deduction.testatorBoughtPropertyDate > today) {
                return `ניכוי מספר ${i} - תאריך הרכישה של המוריש לא יכול להיות תאריך עתידי`;
              } else if (deduction.testatorBoughtPropertyDate < minDeduDateAllowed) {
                return `ניכוי מספר ${i} - תאריך הרכישה של המוריש לא יכול להיות לפני ${minDeduDateAllowed}`;
              }
            }
          }
        }
      }
    }
  };

  mainCalcValidations() {
    const today = new Date();
    const minDeduDateAllowed = new Date("1923-01-01");
    const endMasRehush72 = new Date("1972-03-31");
    const endMasRehush85 = new Date("1985-12-31");
    const endOfDollarSupport = new Date("2018-01-01");

    // Validate every deduction.
    // for (const deduction of this.state.calc.mainCalc.deductions) {
    for (let i = 1; i <= this.state.calc.mainCalc.deductions.length; i++) {
      const deduction = this.state.calc.mainCalc.deductions[i - 1];
      if (!deduction.deduType) {
        return `ניכוי מספר ${i} - סוג הניכוי הינו שדה חובה`;
      }
      if (deduction.deduType === "inheritance") {
        if (!deduction.testatorPassingDate) {
          return `ניכוי מספר ${i} - תאריך פטירת המוריש הינו שדה חובה`;
        } else if (new Date(deduction.testatorPassingDate) > today) {
          return `ניכוי מספר ${i} - תאריך פטירת המוריש לא יכול להיות תאריך עתידי`;
        } else if (new Date(deduction.testatorPassingDate) < minDeduDateAllowed) {
          return `ניכוי מספר ${i} - תאריך פטירת המוריש לא יכול להיות לפני ${moment(minDeduDateAllowed).format("YYYY-MM-DD")}`;
        } else if (new Date(deduction.testatorPassingDate) > new Date(this.state.calc.preCalc.soldPropertyDate)) {
          return `ניכוי מספר ${i} - תאריך פטירת המוריש לא יכול להיות לאחר תאריך המכירה`;
        } else if (new Date(deduction.testatorPassingDate) >= new Date("1981-04-01")) {
          if (!deduction.testatorBoughtPropertyDate) {
            return `ניכוי מספר ${i} - תאריך הרכישה של המוריש הינו שדה חובה`;
          } else if (new Date(deduction.testatorBoughtPropertyDate) > today) {
            return `ניכוי מספר ${i} - תאריך הרכישה של המוריש לא יכול להיות תאריך עתידי`;
          } else if (new Date(deduction.testatorBoughtPropertyDate) < minDeduDateAllowed) {
            return `ניכוי מספר ${i} - תאריך הרכישה של המוריש לא יכול להיות לפני ${moment(minDeduDateAllowed).format("YYYY-MM-DD")}`;
          } else if (new Date(deduction.testatorBoughtPropertyDate) > new Date(this.state.calc.preCalc.soldPropertyDate)) {
            return `ניכוי מספר ${i} - תאריך הרכישה של המוריש לא יכול להיות לאחר תאריך המכירה`;
          } else if (new Date(deduction.testatorBoughtPropertyDate) > new Date(deduction.testatorPassingDate)) {
            return `ניכוי מספר ${i} - תאריך הרכישה של המוריש לא יכול להיות לאחר תאריך פטירתו`;
          } else if (
            this.state.calc.preCalc.cosnstructionsDoneBeforeApril1997 &&
            new Date(deduction.testatorBoughtPropertyDate) > new Date("1997-03-31")
          ) {
            return `ניכוי מספר ${i} - תאריך הרכישה של המוריש לא יכול להיות ב-01/04/1997 או לאחר מכן`;
          }
        }
      } else if (deduction.deduType === "gift" || deduction.deduType === "purchase") {
        if (!deduction.deduDate) {
          return `ניכוי מספר ${i} - תאריך ${deduction.deduType === "purchase" ? "הרכישה" : "קבלת המתנה"} הינו שדה חובה`;
        } else if (new Date(deduction.deduDate) > today) {
          return `ניכוי מספר ${i} - תאריך ${deduction.deduType === "purchase" ? "הרכישה" : "קבלת המתנה"} לא יכול להיות תאריך עתידי`;
        } else if (new Date(deduction.deduDate) < minDeduDateAllowed) {
          return `ניכוי מספר ${i} - תאריך ${deduction.deduType === "purchase" ? "הרכישה" : "קבלת המתנה"} לא יכול להיות לפני ${moment(
            minDeduDateAllowed
          ).format("YYYY-MM-DD")}`;
        } else if (new Date(deduction.deduDate) > new Date(this.state.calc.preCalc.soldPropertyDate)) {
          return `ניכוי מספר ${i} - תאריך ${deduction.deduType === "purchase" ? "הרכישה" : "קבלת המתנה"} לא יכול להיות לאחר תאריך המכירה`;
        } else if (this.state.calc.preCalc.cosnstructionsDoneBeforeApril1997 && new Date(deduction.deduDate) > new Date("1997-03-31")) {
          return `ניכוי מספר ${i} - תאריך ${
            deduction.deduType === "purchase" ? "הרכישה" : "קבלת המתנה"
          } לא יכול להיות ב-01/04/1997 או לאחר מכן`;
        }
        if (deduction.deduCurrency === "usd" && new Date(deduction.deduDate) > endOfDollarSupport) {
          return `ניכוי מספר ${i} - לא ניתן להזין דולר לאחר תאריך 01/01/2018`;
        }
      } else {
        // Validate un-purchase deduction.
        if (!deduction.deduDate) {
          return `ניכוי מספר ${i} - תאריך הניכוי הינו שדה חובה`;
        } else if (new Date(deduction.deduDate) > today) {
          return `ניכוי מספר ${i} - תאריך הניכוי לא יכול להיות תאריך עתידי`;
        } else if (new Date(deduction.deduDate) < minDeduDateAllowed) {
          return `ניכוי מספר ${i} - תאריך הניכוי לא יכול להיות תאריך לפני ${moment(minDeduDateAllowed).format("YYYY-MM-DD")}`;
        }
        if (deduction.deduType === "masRehushUpTo72April") {
          if (new Date(deduction.deduDate) > endMasRehush72) {
            return `ניכוי מספר ${i} - לא יכול להיות אחרי 1972-03-31 לפי הגדרת מס רכוש`;
          }
        }
        if (deduction.deduType === "masRehushFrom72AprilTo86") {
          if (new Date(deduction.deduDate) > endMasRehush85 || new Date(deduction.deduDate) <= endMasRehush72) {
            return `ניכוי מספר ${i} - מס רכוש תקופה שניה חייב להיות בן התאריכים 1985-12-31 ~ 1972-04-01`;
          }
        }
        if (deduction.deduType === "masRehushFrom86") {
          if (new Date(deduction.deduDate) <= endMasRehush85) {
            return `ניכוי מספר ${i} - מס רכוש תקופה שלישית חייב להיות החל משנת 1986`;
          }
        }
        //  else if (deduction.deduDate > this.state.calc.preCalc.soldPropertyDate) {
        //   return `ניכוי מספר ${i} - תאריך הניכוי לא יכול להיות לאחר תאריך המכירה`;
        // }
        else if (!deduction.amount) {
          return `ניכוי מספר ${i} - סכום הניכוי הינו שדה חובה`;
        } else if (deduction.belongToParts.length < 1) {
          return `ניכוי מספר ${i} - הניכוי חייב להיות משוייך לחלק אחד לפחות`;
        } else if (deduction.belongToParts.filter((item) => item > this.state.calc.preCalc.stepsBought).length > 0) {
          return `ניכוי מספר ${i} - הניכוי משוייך לחלק שלא קיים`;
        } else if (deduction.attribution !== "apartment" && !this.state.calc.preCalc.hasZaban) {
          return `ניכוי מספר ${i} - הניכוי מיוחס לזב"ן למרות שלא קיים זב"ן בנכס`;
        }

        if (deduction.deduCurrency === "usd" && new Date(deduction.deduDate) > endOfDollarSupport) {
          return `ניכוי מספר ${i} - לא ניתן להזין דולר לאחר תאריך 01/01/2018`;
        }
      }
      if (!deduction.amount) {
        return `ניכוי מספר ${i} - סכום הרכישה במטבע המקורי הינו שדה חובה`;
      }

      if (Object.keys(deduction.depreciation).length) {
        for (const range of deduction.depreciation.dateRanges) {
          if (!range.startDate) return `ניכוי מספר ${i} - תאריך התחלה הינו שדה חובה`;
          if (new Date(range.startDate) > new Date(this.state.calc.preCalc.soldPropertyDate))
            return `ניכוי מספר ${i} - תאריך התחלה לא יכול להיות לאחר תאריך המכירה`;
          if (new Date(range.startDate) > today) return `ניכוי מספר ${i} - תאריך התחלה לא יכול להיות תאריך עתידי`;
          if (!range.endDate) return `ניכוי מספר ${i} - תאריך סיום הינו שדה חובה`;
          if (new Date(range.endDate) > new Date(this.state.calc.preCalc.soldPropertyDate))
            return `ניכוי מספר ${i} - תאריך הסיום של תקופת הפחת לא יכול להיות לאחר תאריך המכירה`;
          if (new Date(range.endDate) > today) return `ניכוי מספר ${i} - תאריך סיום לא יכול להיות תאריך עתידי`;
          if (new Date(range.startDate) > new Date(range.endDate)) return `ניכוי מספר ${i} - תאריך התחלה לא יכול להיות לאחר תאריך סיום`;
          if (deduction.deduType !== "inheritance") {
            if (new Date(deduction.deduDate) > new Date(range.startDate)) {
              return `ניכוי מספר ${i} - תאריך התחלה לא יכול להיות לפני תאריך הניכוי`;
            }
          } else {
            if (new Date(deduction.testatorPassingDate) >= new Date("1981-04-01")) {
              if (new Date(deduction.testatorBoughtPropertyDate) > new Date(range.startDate))
                return `ניכוי מספר ${i} - תאריך התחלה לא יכול להיות לפני תאריך הניכוי`;
            } else {
              if (new Date(deduction.testatorPassingDate) > new Date(range.startDate)) {
                return `ניכוי מספר ${i} - תאריך התחלה לא יכול להיות לפני תאריך הניכוי`;
              }
            }
          }
        }
      }
    }
    // Validate every client for prisa if needed.
    if (this.state.calc.mainCalc.prisa.shouldTryPrisa) {
      if (this.state.calc.mainCalc.isGilum) {
        return "לא ניתן לעשות פריסה וגילום ביחד";
      }
      for (const person of this.state.calc.mainCalc.prisa.personsData) {
        // if (!person.personName) {
        //   return "שם הלקוח הינו שדה חובה";
        // }
        if (person.dataPerYear.some((year) => !year.zikui && year.zikui !== 0)) {
          return "יש להזין נקודות זיכוי על מנת לבצע פריסה";
        }
        if (person.dataPerYear.some((year) => year.zikui > 100)) {
          return "כמות לא חוקית של נקודות זיכוי";
        }
        if (!person.birthOfClient) {
          return "תאריך הלידה של הנישום הינו שדה חובה";
        } else if (new Date(person.birthOfClient) > today) {
          return "תאריך הלידה של הנישום לא יכול להיות תאריך עתידי";
        } else if (new Date(person.birthOfClient) > new Date(this.state.calc.preCalc.soldPropertyDate)) {
          return "תאריך הלידה של הנישום לא יכול להיות לאחר תאריך המכירה";
        } else if (new Date(person.birthOfClient) < moment(new Date()).add(-100, "years").toDate()) {
          return "תאריך הלידה של הנישום לא יכול להיות יותר ממאה שנה אחורה";
        }
      }
    }
  }

  mainCalcStart = () => {
    const err = this.mainCalcValidations();
    if (err) {
      return Swal.fire("", err, "error");
    } else {
      const mainCalc = this.state.calc.mainCalc;
      if (!mainCalc.prisa.shouldTryPrisa) {
        // If we dont have prisa then make sure that we dont have bs data of prisa sent.
        mainCalc.prisa.personsData = [];
      }

      const data = { preCalc: this.state.calc.preCalc, mainCalc };
      dealService.mainCalcStart(data, this.state.dealId).then((res) => {
        if (res["success"]) {
          const mainCalc = {
            ...this.state.calc.mainCalc,
            calculatedData: res.data.mainCalc.calculatedData,
            hasResults: true
          };
          const calc = { ...this.state.calc, mainCalc };
          this.setState({ calc }, () => this.handleStepChange(3));
        }
      });
    }
  };

  handleStepChange = (newStep) => {
    const calc = { ...this.state.calc, currentStep: newStep, isSnackbarOpen: true, isPreCalcMsgAllowed: true };
    this.setState({ calc });
    window["scrollTo"]({ top: 0, behavior: "smooth" });
  };

  // Pre Calc functions
  handlePreCalcOnChange = (e) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const preCalc = { ...this.state.calc.preCalc, [e.target.name]: val };
    if (e.target.name === "stepsBought") this.initializePrePartCalc(e, preCalc);
    const mainCalc = { ...this.state.calc.mainCalc };
    this.handlePreCalcDependencies(e, preCalc, mainCalc);
    const { dealDate, isDealDateChanged } = this.handleDealDateDependencies(e);
    const calc = { ...this.state.calc, preCalc, mainCalc };
    this.setState({ calc, dealDate, isDealDateChanged });
  };

  handleCombinationOnChange = (e1, e2) => {
    const preCalc = { ...this.state.calc.preCalc, [e1.target.name]: e1.target.value, [e2.target.name]: e2.target.value };
    const mainCalc = { ...this.state.calc.mainCalc };
    this.handlePreCalcDependencies(e1, preCalc, mainCalc);
    this.handlePreCalcDependencies(e2, preCalc, mainCalc);
    const calc = { ...this.state.calc, preCalc, mainCalc };
    this.setState({ calc });
  };

  handleDealDateDependencies(e) {
    if (e.target.name === "soldPropertyDate") {
      const dealDate = e.target.value,
        isDealDateChanged = true;
      return { dealDate, isDealDateChanged };
    } else {
      return { dealDate: this.state.dealDate, isDealDateChanged: this.state.isDealDateChanged };
    }
  }

  handlePreCalcDependencies(e, preCalc, mainCalc) {
    if (preCalc.hasResults) this.clearPreCalcResults(preCalc);
    if (mainCalc.hasResults) this.clearMainCalcResults(mainCalc);
    if (e.target.name === "sellerType") {
      if (e.target.value === "organization") {
        if (preCalc.propertyType === "dmm") {
          preCalc.propertyType = "apartment";
        }
        for (const part of preCalc.preCalcPartsData) {
          part.isMutavAllowed = false;
          part.isInheritancePtorAllowed = false;
          part.isSinglePropertyPtorAllowed = false;
        }
        preCalc.percentHoldingShares = null;
      } else if (e.target.value === "single") {
        if (preCalc.propertyType === "apartment") {
          preCalc.propertyType = "dmm";
          for (const part of preCalc.preCalcPartsData) {
            part.isMutavAllowed = true;
          }
        }
        if (preCalc.propertyType !== "sharesInUnion") {
          preCalc.percentHoldingShares = null;
        }
      }
      if (preCalc.howPropertyIsSold === "combination") {
        if (preCalc.sellerType === "single" && preCalc.propertyType === "dmm") {
          preCalc.hasZaban = true;
          preCalc.totalPropertyCostWithoutZaban = null;
        } else {
          preCalc.hasZaban = false;
          preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
        }
      } else {
        preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
      }
      preCalc.cosnstructionsDoneBeforeApril1997 = false;
      preCalc.totalDealSoldPercent = 1;
    } else if (e.target.name === "propertyType") {
      // IM HERE
      if (e.target.value !== "sharesInUnion" || preCalc.sellerType !== "single") {
        preCalc.percentHoldingShares = null;
      }
      const mutavNewVal = e.target.value === "dmm" ? true : false;
      for (const part of preCalc.preCalcPartsData) {
        part.isMutavAllowed = mutavNewVal;
        part.isInheritancePtorAllowed = false;
        part.isSinglePropertyPtorAllowed = false;
      }
      if (preCalc.howPropertyIsSold === "combination") {
        if (preCalc.sellerType === "single" && preCalc.propertyType === "dmm") {
          preCalc.hasZaban = true;
          preCalc.totalPropertyCostWithoutZaban = null;
        } else {
          preCalc.hasZaban = false;
          preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
        }
      } else {
        preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
        preCalc.hasZaban = false;
        preCalc.zabanMeters = "";
      }
      preCalc.cosnstructionsDoneBeforeApril1997 = false;
      preCalc.totalDealSoldPercent = 1;
    } else if (e.target.name === "soldPropertyDate" && e.target.value >= "2018-01-01") {
      for (const part of preCalc.preCalcPartsData) {
        if (preCalc.propertyType === "dmm") part.isMutavAllowed = true;
        part.hasMutavTzinun = false;
      }
    } else if (e.target.name === "iazamCombinationPercent") {
      preCalc.totalPropertyCost = Number.isFinite(preCalc.valueActualSoldOfProperty)
        ? preCalc.valueActualSoldOfProperty * (100 / (preCalc.iazamCombinationPercent || 100))
        : null;
      if (!preCalc.hasZaban) preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
    } else if (e.target.name === "howPropertyIsSold") {
      if (e.target.value === "allPropertySold") {
        if (this.state.calc.preCalc.howPropertyIsSold === "combination") {
          preCalc.totalPropertyCost = preCalc.valueActualSoldOfProperty;
        }
        preCalc.hasZaban = false;
        preCalc.cosnstructionsDoneBeforeApril1997 = false;
        preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
        preCalc.valueActualSoldOfProperty = preCalc.totalPropertyCost;
        preCalc.totalDealSoldPercent = 1;
      } else if (e.target.value === "partOfPropertySold") {
        if (this.state.calc.preCalc.howPropertyIsSold === "combination") {
          preCalc.totalPropertyCost = preCalc.valueActualSoldOfProperty;
        }
        preCalc.hasZaban = false;
        preCalc.cosnstructionsDoneBeforeApril1997 = false;
        preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
        preCalc.valueActualSoldOfProperty = null;
        preCalc.totalDealSoldPercent = null;
      } else if (e.target.value === "combination") {
        preCalc.valueActualSoldOfProperty = preCalc.totalPropertyCost;
        preCalc.totalPropertyCost = Number.isFinite(preCalc.valueActualSoldOfProperty)
          ? preCalc.valueActualSoldOfProperty * (100 / (preCalc.iazamCombinationPercent || 100))
          : null;
        if (preCalc.sellerType === "single" && preCalc.propertyType === "dmm") {
          preCalc.hasZaban = true;
          preCalc.totalPropertyCostWithoutZaban = null;
        } else {
          preCalc.hasZaban = false;
          preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
        }
        preCalc.cosnstructionsDoneBeforeApril1997 = false;
        preCalc.totalDealSoldPercent = 1;
      }
    } else if (e.target.name === "totalPropertyCost") {
      if (preCalc.howPropertyIsSold !== "partOfPropertySold") {
        preCalc.valueActualSoldOfProperty = e.target.value;
        preCalc.totalDealSoldPercent = preCalc.valueActualSoldOfProperty / preCalc.totalPropertyCost;
      }
      if (!preCalc.hasZaban) {
        preCalc.totalPropertyCostWithoutZaban = e.target.value;
      }
    } else if (e.target.name === "valueActualSoldOfProperty") {
      preCalc.valueActualSoldOfProperty = e.target.value;
      if (preCalc.howPropertyIsSold === "combination") {
        preCalc.totalPropertyCost = preCalc.valueActualSoldOfProperty * (100 / (preCalc.iazamCombinationPercent || 100));
        if (preCalc.sellerType !== "single" || preCalc.propertyType !== "dmm") {
          preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
        }
      }
      preCalc.totalDealSoldPercent = 1;
    } else if (e.target.name === "hasZaban") {
      if (e.target.value === "false") {
        preCalc.totalPropertyCostWithoutZaban = preCalc.totalPropertyCost;
      } else if (e.target.value === "true") {
        preCalc.totalPropertyCostWithoutZaban = null;
      }
      preCalc.cosnstructionsDoneBeforeApril1997 = false;
    } else if (e.target.name === "totalPropertyCostWithoutZaban" && e.target.value > 100000) {
      preCalc.cosnstructionsDoneBeforeApril1997 = false;
    } else if (e.target.name === "isOnlyProperty" && e.target.value === "false") {
      for (const part of preCalc.preCalcPartsData) {
        part.isSinglePropertyPtorAllowed = false;
        part.hasTzinunOnPtor = false;
      }
    }
  }

  handlePreCalcPartOnChange = (e, preCalcPart) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const preCalcPartsData = [...this.state.calc.preCalc.preCalcPartsData];
    preCalcPartsData[preCalcPart][e.target.name] = val;
    const preCalc = { ...this.state.calc.preCalc, preCalcPartsData };
    const mainCalc = { ...this.state.calc.mainCalc };
    this.handlePreCalcPartDependencies(e, preCalcPart, preCalc, mainCalc);
    const calc = { ...this.state.calc, preCalc, mainCalc };
    this.setState({ calc });
  };

  handlePreCalcPartDependencies(e, preCalcPart, preCalc, mainCalc) {
    if (preCalc.hasResults) this.clearPreCalcResults(preCalc);
    if (mainCalc.hasResults) this.clearMainCalcResults(mainCalc);
    if (e.target.name === "wayOfGettingProperty") {
      if (e.target.value !== "inheritance") {
        preCalc.preCalcPartsData[preCalcPart].isInheritancePtorAllowed = false;
      }
      if (e.target.value !== "gift") {
        preCalc.preCalcPartsData[preCalcPart].hasMutavTzinun = false;
        preCalc.preCalcPartsData[preCalcPart].hasTzinunOnPtor = false;
      }
    } else if (e.target.name === "isMutavAllowed" && e.target.value === "false") {
      preCalc.preCalcPartsData[preCalcPart].hasMutavTzinun = false;
    } else if (e.target.name === "isSinglePropertyPtorAllowed" && e.target.value === "false") {
      preCalc.preCalcPartsData[preCalcPart].hasTzinunOnPtor = false;
    }
  }

  clearPreCalcResults(preCalc) {
    preCalc.hasResults = false;
    preCalc.recommendations = null;
    preCalc.miraArielMsg = false;
    preCalc.totalDealSoldPercent = null;
    for (const part of preCalc.preCalcPartsData) {
      if (part.baseAmount !== undefined) delete part.baseAmount;
      if (part.baseMethodType !== undefined) delete part.baseMethodType;
      if (part.deltaAmount !== undefined) delete part.deltaAmount;
      if (part.deltaMethodType !== undefined) delete part.deltaMethodType;
      if (part.zabanAllowedAnyPtor !== undefined) delete part.zabanAllowedAnyPtor;
      if (part.zabanOldLinear !== undefined) delete part.zabanOldLinear;
      if (part.needsMutav !== undefined) delete part.needsMutav;
      if (part.needsRegTax !== undefined) delete part.needsRegTax;
    }
  }

  initializePrePartCalc(e, preCalc) {
    const numOfSteps = e.target.value;
    if (numOfSteps === preCalc.preCalcPartsData.length) return;
    const preCalcPartsData = [];
    for (let i = 0; i < numOfSteps; i++) {
      let precision = 0;
      if (numOfSteps === 3 && i === 0) precision = 0.01;
      if (numOfSteps === 6 && i > 3) precision = -0.01;
      if (preCalc.preCalcPartsData.length > i) {
        const newPart = {
          ...preCalc.preCalcPartsData[i],
          percentBought: parseFloat((100 / numOfSteps + precision).toLocaleString(undefined, { maximumFractionDigits: 2 }))
        };
        preCalcPartsData.push(newPart);
      } else {
        preCalcPartsData.push({
          percentBought: parseFloat((100 / numOfSteps + precision).toLocaleString(undefined, { maximumFractionDigits: 2 })),
          wayOfGettingProperty: "purchase",
          isMutavAllowed: preCalc.propertyType === "dmm" ? true : false,
          isInheritancePtorAllowed: false,
          isSinglePropertyPtorAllowed: false,
          hasTzinunOnPtor: false,
          hasMutavTzinun: false
        });
      }
    }
    preCalc.preCalcPartsData = preCalcPartsData;
  }

  // Main Calc functions:
  handleMainCalcOnChange = (e) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const mainCalc = { ...this.state.calc.mainCalc, [e.target.name]: val };
    this.handleMainCalcDependencies(e, mainCalc);
    const calc = { ...this.state.calc, mainCalc };
    this.setState({ calc });
  };

  handleMainCalcDependencies(e, mainCalc) {
    if (mainCalc.hasResults) this.clearMainCalcResults(mainCalc);
  }

  handleMainCalcPrisaOnChange = (e, prisaPersonData, nLastYear) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const prisa = { ...this.state.calc.mainCalc.prisa };
    if (e.target.name === "shouldTryPrisa") {
      prisa.shouldTryPrisa = val;
      this.initializeMainCalcPersonsData({ target: { value: val ? 1 : 0 } }, prisa);
    } else if (e.target.name === "personsData") {
      this.initializeMainCalcPersonsData(e, prisa);
    } else if (e.target.name === "dataPerYear") {
      this.initializeMainCalcDataPerYear(e, prisa.personsData[prisaPersonData || 0]);
    } else {
      const personsData = [...prisa.personsData];
      if (e.target.name === "income" || e.target.name === "zikui") {
        personsData[prisaPersonData || 0].dataPerYear[nLastYear || 0][e.target.name] = val;
      } else {
        personsData[prisaPersonData || 0][e.target.name] = val;
      }
      prisa.personsData = personsData;
    }
    const mainCalc = { ...this.state.calc.mainCalc, prisa };
    this.handleMainCalcDependencies(e, mainCalc);
    const calc = { ...this.state.calc, mainCalc };
    this.setState({ calc });
  };

  getPurchaseDate = (deduction) => {
    return deduction.testatorPassingDate >= "1981-04-01" ? deduction.testatorBoughtPropertyDate : deduction.testatorPassingDate;
  };

  getPrisaMaxYears = () => {
    let maxYears = 0;
    let hasAllDates = true;
    for (const deduction of this.state.calc.mainCalc.deductions) {
      let tempYears;
      let isPurchaseDeduction = false;
      if (deduction.deduType === "purchase") {
        tempYears = deduction.deduDate ? deduction.deduDate : "";
        isPurchaseDeduction = true;
      } else if (deduction.deduType === "inheritance") {
        tempYears = this.getPurchaseDate(deduction);
        isPurchaseDeduction = true;
      } else if (deduction.deduType === "gift") {
        tempYears = deduction.deduDate ? deduction.deduDate : "";
        isPurchaseDeduction = true;
      }
      if (tempYears && this.state.calc.preCalc.soldPropertyDate) {
        let diff = parseInt(this.state.calc.preCalc.soldPropertyDate.substring(0, 4)) - parseInt(tempYears.substring(0, 4));
        diff = Math.min(4, diff);
        maxYears = Math.max(maxYears, diff);
      } else if (isPurchaseDeduction) {
        hasAllDates = false;
      }
    }
    return hasAllDates ? maxYears : 4;
  };

  getPrisaMinYears = () => {
    let minYears = 4;
    let hasAllDates = true;
    for (const deduction of this.state.calc.mainCalc.deductions) {
      let tempYears;
      let isPurchaseDeduction = false;
      if (deduction.deduType === "purchase") {
        tempYears = deduction.deduDate ? deduction.deduDate : "";
        isPurchaseDeduction = true;
      } else if (deduction.deduType === "inheritance") {
        tempYears = this.getPurchaseDate(deduction);
        isPurchaseDeduction = true;
      } else if (deduction.deduType === "gift") {
        tempYears = deduction.deduDate ? deduction.deduDate : "";
        isPurchaseDeduction = true;
      }
      if (tempYears && this.state.calc.preCalc.soldPropertyDate) {
        let diff = parseInt(this.state.calc.preCalc.soldPropertyDate.substring(0, 4)) - parseInt(tempYears.substring(0, 4));
        diff = Math.max(0, diff);
        minYears = Math.min(minYears, diff);
      } else if (isPurchaseDeduction) {
        hasAllDates = false;
      }
    }
    return hasAllDates ? minYears : 4;
  };

  initializeMainCalcDataPerYear(e, person) {
    const numOfYears = e.target.value;
    if (numOfYears === person.dataPerYear.length) return;
    const dataPerYear = [];
    for (let i = 0; i < numOfYears; i++) {
      if (person.dataPerYear.length > i) {
        dataPerYear.push({ ...person.dataPerYear[i] });
      } else {
        dataPerYear.push({ income: null, zikui: 0 });
      }
    }
    person.dataPerYear = dataPerYear;
  }

  initializeMainCalcPersonsData(e, prisa) {
    const numOfPersons = e.target.value;
    if (numOfPersons === prisa.personsData.length) return;
    const personsData = [];
    const maxYears = this.getPrisaMaxYears();
    for (let i = 0; i < numOfPersons; i++) {
      let newPart;
      if (prisa.personsData.length > i) {
        newPart = { ...prisa.personsData[i], personOwningPercent: 1 / numOfPersons };
      } else {
        newPart = {
          personName: "",
          isMaximal: true,
          personOwningPercent: 1 / numOfPersons,
          birthOfClient: "",
          dataPerYear: []
        };
      }
      this.initializeMainCalcDataPerYear({ target: { value: maxYears } }, newPart);
      personsData.push(newPart);
    }
    prisa.personsData = personsData;
  }

  handleMainCalcDeductionOnChange = (e, mainCalcDeduction) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const deductions = [...this.state.calc.mainCalc.deductions];
    if (e.target.name === "depCurrency") {
      // if (deductions[mainCalcDeduction].depreciation) {
      //   deductions[mainCalcDeduction].depreciation[e.target.name] = val;
      // }
    } else {
      deductions[mainCalcDeduction][e.target.name] = val;
    }
    const mainCalc = { ...this.state.calc.mainCalc, deductions };
    this.handleMainCalcDeductionDependencies(e, mainCalcDeduction, mainCalc);
    const calc = { ...this.state.calc, mainCalc };
    this.setState({ calc });
  };

  handleMainCalcDeductionDependencies(e, mainCalcDeduction, mainCalc) {
    if (mainCalc.hasResults) this.clearMainCalcResults(mainCalc);
    if (e.target.name === "deduDate") {
      if (mainCalc.deductions[mainCalcDeduction].deduCurrency !== "usd") {
        if (e.target.value >= "1986-01-01") {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "newShekel";
        } else if (e.target.value >= "1980-02-24") {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "oldShekel";
        } else {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "lira";
        }
      } else {
        if (e.target.value < "1977-10-31") {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "lira";
        }
      }
      if (e.target.value >= "2004-01-01" || e.target.value <= "2001-11-06") {
        mainCalc.deductions[mainCalcDeduction].hasRabinovich = false;
      }
      // Relevant only for unpurchased deduction that has depreciation.
    } else if (e.target.name === "testatorBoughtPropertyDate") {
      if (mainCalc.deductions[mainCalcDeduction].deduCurrency !== "usd") {
        if (e.target.value >= "1986-01-01") {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "newShekel";
        } else if (e.target.value >= "1980-02-24") {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "oldShekel";
        } else {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "lira";
        }
      } else {
        if (e.target.value < "1977-10-31") {
          mainCalc.deductions[mainCalcDeduction].deduCurrency = "lira";
        }
      }
    } else if (e.target.name === "testatorPassingDate") {
      if (e.target.value < "1981-04-01") {
        if (mainCalc.deductions[mainCalcDeduction].deduCurrency !== "usd") {
          if (e.target.value >= "1980-02-24") {
            mainCalc.deductions[mainCalcDeduction].deduCurrency = "oldShekel";
          } else {
            mainCalc.deductions[mainCalcDeduction].deduCurrency = "lira";
          }
        } else {
          if (e.target.value < "1977-10-31") {
            mainCalc.deductions[mainCalcDeduction].deduCurrency = "lira";
          }
        }
        mainCalc.deductions[mainCalcDeduction].testatorBoughtPropertyDate = "";
      } else if (e.target.value >= "2004-01-01" || e.target.value <= "2001-11-06") {
        mainCalc.deductions[mainCalcDeduction].hasRabinovich = false;
      }
    }
    if (mainCalc.deductions[mainCalcDeduction].depreciation.depCurrency) {
      if (mainCalc.deductions[mainCalcDeduction].depreciation.depType !== "regTax") {
        mainCalc.deductions[mainCalcDeduction].depreciation.depCurrency = mainCalc.deductions[mainCalcDeduction].deduCurrency;
      }
    }
    // Set Max Year for every person if needed.
    const maxYears = this.getPrisaMaxYears();
    const minYears = this.getPrisaMinYears();
    // Clear prisa if prisa isn't possible.
    if (maxYears < 1 || minYears === 0) {
      mainCalc.prisa.shouldTryPrisa = false;
      this.initializeMainCalcPersonsData({ target: { value: 0 } }, mainCalc.prisa);
    }
    for (const person of mainCalc.prisa.personsData) {
      const prisaYearsToSet = this.props.abilities.includes("prisaExt") ? Math.min(maxYears, person.dataPerYear.length) : maxYears;
      this.initializeMainCalcDataPerYear({ target: { value: prisaYearsToSet } }, person);
    }
  }

  clearMainCalcResults(mainCalc) {
    mainCalc.hasResults = false;
    if (mainCalc.calculatedData !== undefined) delete mainCalc.calculatedData;
  }

  initializeMainCalc(mainCalc, preCalc, isPartlyDeletion) {
    const deductions = [];
    for (let index = 0; index < preCalc.preCalcPartsData.length; index++) {
      const part = preCalc.preCalcPartsData[index];
      const deduction = {
        deduType: part.wayOfGettingProperty,
        deduCurrency: "newShekel",
        hasRabinovich: false,
        attribution: preCalc.hasZaban ? "both" : "apartment",
        belongToParts: [index + 1]
      };

      if (!isPartlyDeletion) {
        deduction.amount = null;
        deduction.depreciation = {};
        if (part.wayOfGettingProperty === "inheritance") {
          deduction.testatorBoughtPropertyDate = "";
          deduction.testatorPassingDate = "";
        } else {
          deduction.deduDate = "";
        }
      } else {
        const getFirstDeduction =
          mainCalc.deductions && mainCalc.deductions[index] && dealsService.isPurchaseDeduction(mainCalc.deductions[index].deduType)
            ? mainCalc.deductions[index]
            : {};

        deduction.amount = getFirstDeduction.amount;
        deduction.depreciation = getFirstDeduction.depreciation || {};
        deduction.deduCurrency = getFirstDeduction.deduCurrency || "newShekel";

        if (part.wayOfGettingProperty === "inheritance") {
          deduction.testatorBoughtPropertyDate = getFirstDeduction.testatorBoughtPropertyDate || "";
          deduction.testatorPassingDate = getFirstDeduction.testatorPassingDate || "";
        } else {
          deduction.deduDate = getFirstDeduction.deduDate || "";
        }
      }

      deductions.push(deduction);
    }

    if (isPartlyDeletion) {
      const getNonePurchaseDeductions = mainCalc.deductions.filter((deduction) => !dealsService.isPurchaseDeduction(deduction.deduType));

      for (let index = 0; index < getNonePurchaseDeductions.length; index++) {
        const currentDeduction = getNonePurchaseDeductions[index];
        if (!preCalc.hasZaban) currentDeduction.attribution = "apartment";
        currentDeduction.belongToParts = currentDeduction.belongToParts.filter(
          (belongToPart) => belongToPart <= preCalc.preCalcPartsData.length
        );

        deductions.push(currentDeduction);
      }

      mainCalc.deductions = deductions;

      // Removing override of tkufa percents everytime pre calc is being newly calculated
      delete mainCalc.overrideTkufaPercent;

      return mainCalc;
    }

    const prisa = {
      shouldTryPrisa: false,
      personsData: []
    };
    mainCalc.prisa = prisa;
    mainCalc.kizuzIski = null;
    mainCalc.kizuzHoni = null;
    mainCalc.isGilum = false;
    mainCalc.afkaa = false;
    mainCalc.hasResults = false;
    mainCalc.deductions = deductions;

    return mainCalc;
  }

  addMainCalcDeduction = () => {
    const mainCalc = { ...this.state.calc.mainCalc };
    const deductions = [...mainCalc.deductions];
    deductions.push({
      deduType: "expenseGeneralImprovement",
      deduDate: "",
      amount: null,
      deduCurrency: "newShekel",
      hasRabinovich: false,
      attribution: "apartment",
      belongToParts: [1],
      depreciation: {}
    });
    mainCalc.deductions = deductions;
    const calc = { ...this.state.calc, mainCalc };
    this.setState({ calc });
  };

  addMainCalcDeductionsFromExcel = (excelDeductions) => {
    const mainCalc = { ...this.state.calc.mainCalc };
    const deductions = [...mainCalc.deductions];

    if (excelDeductions.length + deductions.length > config.maxDeductionsAllowed) {
      Swal.fire(`ניתן לכל היותר להזין ${config.maxDeductionsAllowed} ניכויים`, "", "warning");
      return;
    }
    const allDeduTypes = dealsService.getDeductionTypeOptions();

    for (const curDedu of excelDeductions) {
      let getCurDeductionType = "";
      if (curDedu["סוג ניכוי"]) {
        getCurDeductionType = allDeduTypes.filter((x) => x.label === curDedu["סוג ניכוי"]);
        if (getCurDeductionType && getCurDeductionType[0]) getCurDeductionType = getCurDeductionType[0].value;
        else getCurDeductionType = "";
      }
      const deduDate = curDedu["תאריך ניכוי"] ? moment(curDedu["תאריך ניכוי"]).add(10, "h").format("YYYY-MM-DD") : "";

      deductions.push({
        deduType: getCurDeductionType,
        deduDate,
        amount: curDedu["סכום"] || "",
        deduCurrency: "newShekel",
        hasRabinovich: false,
        attribution: "apartment",
        belongToParts: [1],
        depreciation: {}
      });
    }

    mainCalc.deductions = deductions;
    const calc = { ...this.state.calc, mainCalc };
    this.setState({ calc });
  };

  removeMainCalcDeduction = (deductionIndex) => {
    const mainCalc = { ...this.state.calc.mainCalc };
    const deductions = mainCalc.deductions.filter((deduction, index) => index !== deductionIndex);
    mainCalc.deductions = deductions;
    const calc = { ...this.state.calc, mainCalc };
    this.setState({ calc });
  };

  // Manage Tab functions:
  handleManageOnChange = (e) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const manage = { ...this.state.manage, [e.target.name]: val };
    this.setState({ manage });
  };

  // Docs Tab functions:
  changeDocsStep = (step) => {
    const docs = { ...this.state.docs, currentStep: step };
    this.setState({ docs });
  };

  handleDocTypesOnChange = (e) => {
    let val = e.target.value;
    if (val === "true") val = true;
    if (val === "false") val = false;
    const docTypes = { ...this.state.docs.docTypes, [e.target.name]: val };
    const docs = { ...this.state.docs, docTypes };
    this.handleDocTypesDependencies(e, docs);
    this.setState({ docs });
  };

  handleDocTypesDependencies(e, docs) {
    if (e.target.name === "taboAndRMIType") {
      docs.docTypes.shtarMecher = false;
      docs.docTypes.eaaratAzharaOneSide = false;
      docs.docTypes.eaaratAzharaAllSides = false;
      docs.docTypes.noTaboLeasingRight = false;
      docs.docTypes.taboPassingLeasingRight = false;
      docs.docTypes.taboFixLeasingRight = false;
      docs.docTypes.taboCancelLeasingRight = false;
      docs.docTypes.taboRegisterLeasingRight = false;
    }
    // isFillDocumnetsAllowed set to true only if at least one it true.
    docs.isFillDocumnetsAllowed =
      docs.docTypes.shtarMecher ||
      docs.docTypes.eaaratAzharaOneSide ||
      docs.docTypes.eaaratAzharaAllSides ||
      docs.docTypes.noTaboLeasingRight ||
      docs.docTypes.taboPassingLeasingRight ||
      docs.docTypes.taboFixLeasingRight ||
      docs.docTypes.taboCancelLeasingRight ||
      docs.docTypes.taboRegisterLeasingRight ||
      docs.docTypes.ipuiKoachBuyer ||
      docs.docTypes.ipuiKoachSeller ||
      docs.docTypes.ipuiKoachEaaratAzhara ||
      docs.docTypes.fillDetailFileOnline;
  }

  handleSnapbarClose = () => {
    const calc = { ...this.state.calc, isSnackbarOpen: false };
    this.setState({ calc });
  };

  hasTaxToPay = () => {
    for (let part of this.state.calc.preCalc.preCalcPartsData) {
      if (part.baseAmount && part.baseAmount > 0 && ["REG_TAX", "TAX_MUTAV"].includes(part.baseMethodType)) {
        return true;
      }
      if (part.deltaAmount && part.deltaAmount > 0 && ["REG_TAX", "TAX_MUTAV"].includes(part.deltaMethodType)) {
        return true;
      }
      if (part.zabanOldLinear && part.zabanOldLinear > 0) {
        return true;
      }
      if (!["SINGLE_PROPERTY_PTOR", "INHERITANCE_PTOR"].includes(part.baseMethodType) && part.zabanAllowedAnyPtor) {
        return true;
      }
    }
    return false;
  };
}

const mapStateToProps = (state) => {
  return {
    abilities: state.loggedinReducer.abilities
  };
};

export default connect(mapStateToProps)(DealsContainer);
